/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import Link from 'gatsby-link'
import { graphql } from 'gatsby'
// import { routes, t } from '../../utils'
import {
  Page,
  H1,
  H2,
  Flex,
  Hr,
  FeatherIcon,
  Card,
  Text,
  Paper,
  Box,
} from '../../elements'
import {
  ExhibitorEntry,
  AnimationEntry,
  HeadingStaticPage,
  SEO,
  SectionExhibitors,
  SectionAnimations,
  SectionBoardgames,
  SectionGuests,
} from '../../components'
import { FavoritesStore } from '../../containers'
import { theme } from '../../utils'

const text = {
  fr: `
<p>Pour sauvegarder en favoris, cliquez sur les étoiles à côté des titres ou dans les listes.
<br />
Les favoris sont sauvegard&eacute;s sur l'appareil, dans le cache de votre navigateur. Pensez donc &agrave;:</p>
<ul>
<li>les sauvegarder sur l'appareil que vous utiliserez durant le BGF</li>
<li>ne pas ouvrir le site en mode "navigation priv&eacute;e".</li>
<li>ne pas vider votre cache avant le BGF</li>
</ul>`,
  en: `
<p>To save an entry in you favorites' list, clic on the star icon next to its title or the lists.
<br />
Favorites are saved on your device in your browser's cache.
<br />
Some practical advice:</p>
<ul>
<li>save your favorites on the device you will be using during the BGF</li>
<li>don't use "Private Browsing"</li>
<li>don't empty your cache before the BGF</li>
</ul>
`,
  nl: `
<p>Om een post op te slaan, klik op het sterretje naast de titel of in the lijsten.
<br />
De favorieten worden opgeslagen op het toestel, in de cache van je browser. Denk er dus aan om:</p>
<ul>
<li>om ze op te slaan op het toestel dat je tijdens het BGF gaat gebruiken</li>
<li>de website niet te openen in "priv&eacute; navigatie"</li>
<li>je cache niet te verwijderen voor het BGF</li>
</ul>
`,
}

const FavoritesPage = ({ exhibitors, animations, boardgames, guests }) => (
  <Page>
    {({ t, currentLocale }) => (
      <React.Fragment>
        <SEO
          {...{
            url: `/favorites/`,
            title: t('favorites'),
            // description: true,
          }}
        />
        <HeadingStaticPage
          {...{
            icon: 'star',
            stroke: theme.colors.textAlt,
            fill: theme.colors.textAlt,
            bg: 'lightGreen',
            color: 'textAlt',
            title: t('favorites'),
            mb: 0,
          }}
        />

        <SectionExhibitors
          {...{ exhibitors, t, headingPaperSection: { mt: 0 } }}
        />
        <SectionAnimations
          {...{ animations, t, headingPaperSection: { mt: 0 } }}
        />
        <SectionBoardgames
          {...{ boardgames, t, headingPaperSection: { mt: 0 } }}
        />
        <SectionGuests {...{ guests, t, headingPaperSection: { mt: 0 } }} />

        <Box.container>
          <Paper>
            <Text
              {...{
                as: 'p',
                color: 'text',
                className: 'break show-links',

                dangerouslySetInnerHTML: { __html: text[currentLocale] },
              }}
            />
          </Paper>
        </Box.container>

        {/* <Paper
          {...{
            width: '100%',
            maxWidth: '90%',
            m: 'auto',
            mt: 3,
          }}
        >
          <Text {...{}}>{t('comingSoon')}</Text>
        </Paper> */}
      </React.Fragment>
    )}
  </Page>
)
// export default FavoritesPage

export default ({ data }) => (
  <FavoritesStore>
    {({ favorites }) => {
      // TODO: Memoize

      // favs is a map of favorites like { exhibitors: { act-in-games: true } }
      const favs = favorites.reduce(
        (acc, { family, nameCanonical }) => {
          return {
            ...acc,
            [`${family}s`]: {
              ...acc[`${family}s`],
              [nameCanonical]: true,
            },
          }
        },
        { exhibitors: {}, animations: {}, boardgames: {}, guests: {} },
      )
      // favsSplitted is a an obj of arrays with the 4 lists
      const favsSplitted = [
        'exhibitors',
        'animations',
        'boardgames',
        'guests',
      ].reduce(
        (acc, fam) => {
          return data[fam]
            ? {
                ...acc,
                [fam]: data[fam].nodes.filter(
                  ({ nameCanonical }) => !!favs[fam][nameCanonical],
                ),
              }
            : acc
        },
        { exhibitors: [], animations: [], boardgames: [], guests: [] },
      )

      return <FavoritesPage {...favsSplitted} />
    }}
  </FavoritesStore>
)

export const pageQuery = graphql`
  query Favorites(
    $localizedFieldNameEx: ExhibitorFieldsEnum!
    $localizedFieldNameAn: AnimationFieldsEnum!
    $localizedFieldNameBo: BoardgameFieldsEnum!
  ) {
    exhibitors: allExhibitor(
      filter: { status: { eq: "published" } }
      sort: { fields: [$localizedFieldNameEx] }
    ) {
      nodes {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        category
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }
    }
    animations: allAnimation(
      filter: { status: { eq: "published" } }
      sort: { fields: [start, end, $localizedFieldNameAn] }
    ) {
      nodes {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        days
        start
        end
        categories
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
        boardgames {
          featuredImage {
            id
            url
            size
            thumbnails {
              small {
                height
                width
                url
              }
              large {
                url
                width
                height
              }
            }
          }
        }
      }
    }
    boardgames: allBoardgame(
      filter: { status: { eq: "published" } }
      sort: { fields: [$localizedFieldNameBo] }
    ) {
      nodes {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        proto
        duration
        facebook
        featuredImage {
          url
          type
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              height
              width
              url
            }
          }
        }
        accessibility
        age
        audience
        maxPlayers
        minPlayers
        relativePath
      }
    }

    guests: allGuest(
      filter: { status: { eq: "published" } }
      sort: { fields: [nameCanonical] }
    ) {
      nodes {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }
    }
  }
`
